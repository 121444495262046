.image-gallery {
  height: 100%;
  user-select: none;
  position: relative;
}

.image-gallery-slides {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  line-height: 0;
  text-align: center;
}
